<template>
  <div v-if="context == 'preview'">
    <a :href="block.url" :class="block.class" v-html="block.text"></a>
  </div>

  <div v-else class="space-y-2 pt-2">
    <p class="font-bold">{{ settingsLabel }}</p>
    <rw-text 
      v-model="block.text" 
      placeholder="Enter the text" 
      label="Text" 
      :layout="layout" 
      v-if="showTextInput"
    />
    <rw-text
      v-model="block.class"
      placeholder="Enter the class"
      label="Class"
      :layout="layout"
      v-if="showClassInput"
    />
    <rw-select v-model="block.type" :options="linkTypes" label="Link type" :layout="layout" />

    <template v-if="block.type == 'custom'">
      <rw-text v-model="block.url" label="URL" :layout="layout" :max-length="500" />
    </template>

    <div v-if="block.type == 'download'" class="my-4 flex justify-between">
      <div>
        <cloudinary btnText="Browse Cloudinary" :fieldUid="$.uid" />
        <button @click="showModal = true" class="ml-2 btn px-2" v-if="block.url">
          <fa-icon icon="cog" />
        </button>
      </div>
      <button class="btn btn-stop ml-2 px-2" @click="removeDownload" v-if="block.url">
        <fa-icon icon="trash" />
      </button>
    </div>

    <template v-if="block.type == 'entry' && $route.params.siteID">
      <page-picker 
        v-model="block.entryId" 
        :siteID="$route.params.siteID" 
        label="Page" />
    </template>

    <template v-if="block.type == 'form' && $route.params.siteID">
      <rw-text v-model="block.formAction" label="Form Action" />
      <rw-select 
        v-model="block.encrypt" 
        :options="encryptTypes" 
        :includeNull="false" 
        label="Encryption type (default: application/x-www-form-urlencoded)" 
        :layout="layout" />
      <div class="space-x-2 flex" v-for="(field, idx) in block.formFields" :key="idx">
        <rw-text v-model="field.name" label="Name" />
        <rw-text v-model="field.value" label="Value" />
        <button @click="block.formFields.splice(idx, 1)">
          <fa-icon icon="minus-circle" class="mt-4" />
        </button>
      </div>
      <button class="btn" @click="addField">Add Form Field</button>
    </template>

    <modal-dialog :show="showModal" @close="showModal = false">
      <rw-text v-model="block.url" placeholder="Enter the src" label="Src" class="mb-2" />
      <p class="m-0 break-all" v-for="(attribute, id) in block.cloudinary" :key="id">
        <strong>{{ id }}:</strong>
        {{ attribute }}
      </p>
    </modal-dialog>
  </div>
</template>

<script>
import { link as linkSchema } from '@/settings/blocks'
import cloneDeep from 'lodash/cloneDeep'
import defaultsDeep from 'lodash/defaultsDeep'
import pick from 'lodash/pick'
import EventBus from '@/bootstrap/EventBus'
import { reactive } from 'vue'

export default {
  name: 'Link',
  mixins: [BlockMixin],
  props: {
    settingsLabel: {
      type: String,
      default: 'Link',
    },
    layout: {
      type: String,
      default: '',
    },
    types: {
      type: Array,
      default() {
        return ['entry', 'custom', 'download']
      },
    },
    showClassInput: {
      type: Boolean,
      default: true,
    },
    showTextInput: {
      type: Boolean,
      default: true,
    },
  },
  created() {
    this.block = defaultsDeep(reactive(this.modelValue), cloneDeep(linkSchema))
  },
  mounted() {
    EventBus.on('asset-chosen', this.updateDownload)
  },
  beforeUnmounted() {
    EventBus.off('asset-chosen')
  },
  data() {
    return {
      showModal: false,
      block: {},
    }
  },
  watch: {
    // If the URL or entry gets set, reset the other option to keep it clean.
    'block.url': function (val) {
      if (val) {
        this.block.entryId = null;
      }
    },
    'block.entryId': function (val) {
      if (val) {
        this.block.url = null;
        this.block.cloudinaryId = null;
        this.block.cloudinary = null;
      }
    },
  },
  computed: {
    linkTypes() {
      let linkTypes = {
        entry: 'Entry',
        custom: 'Custom',
        download: 'Download',
        form: 'Form',
      }
      // Get an array by intersecting props.types with linkTypes
      let allowed = this.types.filter(item => Object.keys(linkTypes).includes(item))

      return pick(linkTypes, allowed)
    },
    encryptTypes() {
      return {
        'application_x-www-form-urlencoded': 'application/x-www-form-urlencoded',
        'multipart_form-data': 'multipart/form-data',
      };
    }
  },
  methods: {
    updateDownload(data) {
      if (this.$.uid === data.uid) {
        this.block.url = data.asset.secure_url
        this.block.cloudinaryId = data.asset.public_id
        this.block.cloudinary = data.asset
      }
    },
    removeDownload() {
      this.block.url = null
      this.block.cloudinaryId = null
      this.block.cloudinary = []
    },
    addField() {
      let field = Object.assign({}, { name: null, value: null })
      this.block.formFields.push(field)
    },
  },
}
</script>